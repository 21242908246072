import React, { useState, useEffect } from 'react'
import { addFlashMessage } from '../../../../api/app/behaviors/flash-messages'
import { useDispatch } from 'react-redux'
import config from '../../../../../client/config.client'

// check server status - can we reach /health-check endpoint?
const checkOnlineStatus = async () => {
	try {
		const controller = new AbortController()
		const timeout = 3000 // in ms
		const timer = setTimeout(() => controller.abort(), timeout) // abort request when timeout elapses
		const response = await fetch(`${config.baseUrl}/api/v2/health-check`, {
			signal: controller.signal,
		})
		clearTimeout(timer)

		if (response.ok) {
			return true
		} else {
			return false
		}
	} catch (err) {
		console.error(`ERROR fetching api/v2/health-check: ${err}`)
		return false
	}
}

const Offline = () => {
	const [isOnline, setIsOnline] = useState()
	const [isServerOk, setIsServerOk] = useState()
	const dispatch = useDispatch()
	let message = 'Your device may be offline, check internet connection.'
	let callToAction = {
		text: 'Reload',
		callback: function () {
			window.location.reload()
		},
	}

	useEffect(() => {
		let intervalId
		let count = 0

		// server status check
		const fetchServerStatus = async () => {
			try {
				const isServer = await checkOnlineStatus()
				// keep a count of pings and only set to false if 2 pings fail in a row.
				if (isServer == false && count < 1) {
					count += 1
				} else if (isServer == false) {
					count = 0
					setIsServerOk(isServer)
				} else {
					count = 0
					setIsServerOk(isServer)
				}
			} catch (err) {
				console.error(`ERROR fetching /api/v2/health-check: ${err}`)
				setIsServerOk(false)
			}
		}
		// ping server every 7 seconds
		const startInterval = async () => {
			intervalId = setInterval(fetchServerStatus, 7000)
		}

		process.env.NODE_ENV === 'development' ? null : startInterval()

		return () => {
			clearInterval(intervalId) // Clear interval when the component unmounts
		}
	}, [])

	// checks if browser is back online and reloads page if it is.
	useEffect(() => {
		const handleOnline = () => {
			setIsOnline(true)
		}

		const handleOffline = () => {
			setIsOnline(false)
		}

		window.addEventListener('online', handleOnline)
		window.addEventListener('offline', handleOffline)

		return () => {
			window.removeEventListener('online', handleOnline)
			window.removeEventListener('offline', handleOffline)
		}
	}, [])

	// Display flashmessage and reload to offline page if server is not responding or page is offline.
	useEffect(() => {
		if (isServerOk === false) {
			dispatch(addFlashMessage('error', message, callToAction))
		}
	}, [isServerOk])

	return <></>
}

export default Offline
